import { freeSet } from "@coreui/icons";

import { cuSygnet } from "./sygnet";
import { cuLogo } from "./logo";
import { cuLogoNegative } from "./logo-negative";

import {
  // aralab
  ltAdmin,
  ltHome,
  ltHistory,
  ltChamber,
  ltLog,
  ltRules,
  ltConfig,
  ltService,

  // viplant
  ltSite,

  // digidelta
  ltMachine1,
  ltPerformance,
  ltCalculations,
  ltTeamCenter,
  ltEbr,
  ltPlanning,

  //
  ltRawMaterials,
  ltGroup,
  ltText,
  ltSignature,
  ltNumeric,
  ltNumber,
  ltInterface,
  ltMath,
  ltSwitch,
  ltDate,
  ltChecklist,
  ltTitle,
  ltSection,
  ltDropdown,
  ltFileupload,
  ltUserinput,
  ltTemperature,
  ltHumidity,
  ltBurner,
  ltMode,
  ltWindowside,
  ltWindowtop,
  ltShadow,
  ltGraph,
  ltWindSpeed,
  ltWindDir,
  ltRainRate,
  ltRaining,
  ltMobile,
  ltTablet,
  ltLaptop,
  ltDesktop,
  ltLargeDesktop,
  ltExtraLargeDesktop,
  ltSave,
  ltLayers,
  ltPencil,
  ltPlus,
  ltSeparator,
  ltOptions,
  ltClose,
  ltCalendar,
  ltRecipe,
  ltMaintenace,
  ltQuality,
  ltMachine,
  ltMachine2,
  ltReporting,
  ltEnergy,
  ltProcessing,
  ltPending,
  ltSent,
  ltAcknowledge,
  ltDeployok,
  ltDeployNotok,
  ltCanceled,
  ltCancel,
  ltLeft,
  ltDropdownIndicator,
  //cisChevronLeft,
} from "./lumitio";

export const icons = Object.assign(
  {},
  {
    ...freeSet,
  },
  {
    // aralab
    ltAdmin,
    ltHome,
    ltHistory,
    ltChamber,
    ltLog,
    ltRules,
    ltConfig,
    ltService,

    // viplant
    ltSite,

    // digidelta
    ltMachine1,
    ltPerformance,
    ltCalculations,
    ltTeamCenter,
    ltEbr,
    ltPlanning,
    //

    ltRawMaterials,
    ltGroup,
    ltText,
    ltSignature,
    ltNumeric,
    ltNumber,
    ltInterface,
    ltMath,
    ltSwitch,
    ltDate,
    ltChecklist,
    ltTitle,
    ltSection,
    ltDropdown,
    ltFileupload,
    ltUserinput,
    ltTemperature,
    ltHumidity,
    ltBurner,
    ltMode,
    ltWindowside,
    ltWindowtop,
    ltShadow,
    ltGraph,
    ltWindSpeed,
    ltWindDir,
    ltRainRate,
    ltRaining,
    ltMobile,
    ltTablet,
    ltLaptop,
    ltDesktop,
    ltLargeDesktop,
    ltExtraLargeDesktop,
    ltSave,
    ltLayers,
    ltPencil,
    ltPlus,
    ltSeparator,
    ltOptions,
    ltLeft,
    ltDropdownIndicator,
    //cisChevronLeft,
    ltClose,

    ltCalendar,
    ltRecipe,
    ltMaintenace,
    ltQuality,
    ltMachine,
    ltMachine2,
    ltReporting,
    ltEnergy,
    ltProcessing,
    ltPending,
    ltSent,
    ltAcknowledge,
    ltDeployok,
    ltDeployNotok,
    ltCanceled,
    ltCancel,
  },
  {
    cuSygnet,
    cuLogo,
    cuLogoNegative,
  }
);
