import { createStore } from 'redux'
import throttle from 'lodash.throttle';

import { loadState, saveState } from './localStorage';

const initialState = {
  menus: undefined,
  links: undefined,
  schemas: undefined,
  accessToken: undefined,
  profile: undefined,
  sidebarShow: false,
  sidebarCount: 0,
  viewUrl: undefined,
  viewId: '',
  viewLoading: true,
  view: undefined,
  viewError: undefined,
  params: undefined,
  widgetState: undefined,
  editMode: false,
  editBreakpoint: undefined,
  currentBreakpoint: 'lg',
  inactivityModal: false,
  addWidget: false,
  userCanUpdate: false,
  userCanDelete: false,
  saveView: false,
  version: false,
}

const changeState = (state = initialState, { type, ...rest }) => {
  //console.log('changeState:', rest);

  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState, loadState())

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

export default store