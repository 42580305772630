// icons sidebar aralab
export const ltAdmin = [
  "18.017 17.164",
  `
  <g id="Icon_feather-lock" data-name="Icon feather-lock" transform="translate(-4 -3.5)">
    <path id="Path_3281" data-name="Path 3281" d="M6.177,16.5h11.74a1.677,1.677,0,0,1,1.677,1.677v5.87a1.677,1.677,0,0,1-1.677,1.677H6.177A1.677,1.677,0,0,1,4.5,24.047v-5.87A1.677,1.677,0,0,1,6.177,16.5Z" transform="translate(0 -5.984)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_3282" data-name="Path 3282" d="M10.5,10.516V8.193a4.193,4.193,0,1,1,8.386,0v2.323" transform="translate(-2.646 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </g>
`,
];
export const ltHome = [
  "19 19",
  `
  <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" fill="#fff" opacity="0"/>
  <g id="Group_4047" data-name="Group 4047" transform="translate(-3029.603 89.796)">
    <g id="Group_4046" data-name="Group 4046" transform="translate(3030 -88.338)">
      <g id="Group_4044" data-name="Group 4044" transform="translate(0 0)">
        <path id="Path_3310" data-name="Path 3310" d="M765.083,643.078l9.1-7.554,9.1,7.554" transform="translate(-765.083 -635.524)" fill="none" stroke="#030504" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_3311" data-name="Path 3311" d="M780.454,641.446V651.98h-4.431v-5.531h-3.746v5.531h-4.322V641.446" transform="translate(-765.164 -636.099)" fill="none" stroke="#030504" stroke-linejoin="round" stroke-width="1"/>
      </g>
    </g>
  </g>
`,
];
export const ltHistory = [
  "18 18",
  `
  <g id="Group_4000" data-name="Group 4000" transform="translate(-3741 -121)">
    <g id="Group_3999" data-name="Group 3999" transform="translate(5.57 -2.43)">
      <g id="Ellipse_443" data-name="Ellipse 443" transform="translate(3735.43 123.43)" fill="none" stroke="#000" stroke-width="1">
        <circle cx="9" cy="9" r="9" stroke="none"/>
        <circle cx="9" cy="9" r="8.5" fill="none"/>
      </g>
      <path id="Path_3291" data-name="Path 3291" d="M239.484,111.951l-.019,6.262h4.572v-1.138h-3.43l.016-5.12Z" transform="translate(3503.863 15.685)"/>
    </g>
    <path id="Path_3292" data-name="Path 3292" d="M239.486,113.951l-.021,5h4.826v-1.272h-3.55l.017-3.724Z" transform="translate(3502.433 7.687)"/>
  </g>
`,
];

export const ltChamber = [
  "13 17",
  `
  <g id="Group_4010" data-name="Group 4010" transform="translate(-227.557 -25.935)">
    <g id="Group_3826" data-name="Group 3826" transform="translate(227.429 25.935)">
      <g id="Rectangle_20052" data-name="Rectangle 20052" transform="translate(0.128 0)" fill="none" stroke="#000" stroke-width="1">
        <rect width="13" height="16" rx="1" stroke="none"/>
        <rect x="0.5" y="0.5" width="12" height="15" rx="0.5" fill="none"/>
      </g>
      <g id="Rectangle_20053" data-name="Rectangle 20053" transform="translate(4.128 0)" fill="none" stroke="#000" stroke-width="1">
        <path d="M0,0H5A0,0,0,0,1,5,0V9.5A2.5,2.5,0,0,1,2.5,12h0A2.5,2.5,0,0,1,0,9.5V0A0,0,0,0,1,0,0Z" stroke="none"/>
        <path d="M.786.5H4.214A.286.286,0,0,1,4.5.786V9.5a2,2,0,0,1-2,2h0a2,2,0,0,1-2-2V.786A.286.286,0,0,1,.786.5Z" fill="none"/>
      </g>
    </g>
    <g id="Rectangle_20055" data-name="Rectangle 20055" transform="translate(230.557 40.935)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1">
      <rect width="2" height="2" stroke="none"/>
      <rect x="0.5" y="0.5" width="1" height="1" fill="none"/>
    </g>
    <g id="Rectangle_20056" data-name="Rectangle 20056" transform="translate(235.557 40.935)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1">
      <rect width="2" height="2" stroke="none"/>
      <rect x="0.5" y="0.5" width="1" height="1" fill="none"/>
    </g>
  </g>
`,
];

export const ltLog = [
  "17 16.76",
  `
  <g id="Group_4011" data-name="Group 4011" transform="translate(-378.253 -586.665)">
    <g id="Group_4005" data-name="Group 4005" transform="translate(378.753 587.165)">
      <line id="Line_516" data-name="Line 516" x2="8.737" transform="translate(2.912 4.368)" fill="none" stroke="#030504" stroke-width="1"/>
      <line id="Line_517" data-name="Line 517" x2="4.854" transform="translate(2.912 7.28)" fill="none" stroke="#030504" stroke-width="1"/>
      <line id="Line_518" data-name="Line 518" x2="2.912" transform="translate(2.912 10.193)" fill="none" stroke="#030504" stroke-width="1"/>
      <g id="Group_4004" data-name="Group 4004">
        <path id="Path_3295" data-name="Path 3295" d="M393.314,595.9V587.65a.486.486,0,0,0-.485-.485h-13.59a.486.486,0,0,0-.485.485v13.59a.486.486,0,0,0,.485.485h8.7" transform="translate(-378.753 -587.165)" fill="none" stroke="#030504" stroke-width="1"/>
        <path id="Path_3296" data-name="Path 3296" d="M393.753,595.587" transform="translate(-379.192 -587.411)" fill="none" stroke="#030504" stroke-width="1"/>
        <path id="Path_3297" data-name="Path 3297" d="M387.175,602.165" transform="translate(-378.999 -587.604)" fill="none" stroke="#030504" stroke-width="1"/>
      </g>
    </g>
    <ellipse id="Ellipse_444" data-name="Ellipse 444" cx="4.083" cy="4.083" rx="4.083" ry="4.083" transform="translate(386.716 594.897)" fill="none" stroke="#030504" stroke-width="1"/>
    <path id="Path_3298" data-name="Path 3298" d="M392.2,596.829v2.94h1.575" transform="translate(-1.538 -0.341)" fill="none" stroke="#030504" stroke-width="1"/>
  </g>
`,
];

export const ltRules = [
  "17 17.17",
  `
  <g id="Group_4017" data-name="Group 4017" transform="translate(-3585.5 49.888)">
    <g id="Group_4015" data-name="Group 4015" transform="translate(3586 -49.388)">
      <ellipse id="Ellipse_455" data-name="Ellipse 455" cx="3.336" cy="3.336" rx="3.336" ry="3.336" transform="translate(0)" fill="none" stroke="#030504" stroke-width="1"/>
      <path id="Path_3299" data-name="Path 3299" d="M408.049,557.829h7.6a1.743,1.743,0,0,1,1.742,1.745h0a1.744,1.744,0,0,1-1.742,1.745h-7.6" transform="translate(-402.049 -556.237)" fill="none" stroke="#030504" stroke-width="1"/>
    </g>
    <g id="Group_4016" data-name="Group 4016" transform="translate(3601.341 -33.882) rotate(180)">
      <ellipse id="Ellipse_455-2" data-name="Ellipse 455" cx="3.336" cy="3.336" rx="3.336" ry="3.336" transform="translate(0 0)" fill="none" stroke="#030504" stroke-width="1"/>
      <path id="Path_3299-2" data-name="Path 3299" d="M0,0H7.6A1.743,1.743,0,0,1,9.342,1.745h0A1.744,1.744,0,0,1,7.6,3.49H0" transform="translate(6 1.592)" fill="none" stroke="#030504" stroke-width="1"/>
    </g>
  </g>
`,
];

export const ltConfig = [
  "19 19",
  `
  <g id="settings" transform="translate(0.2 0.2)">
    <path id="Path_663" data-name="Path 663" d="M17.363,7.119H15.651A.658.658,0,0,1,15.186,6L16.4,4.786a.943.943,0,0,0,0-1.333L14.853,1.909a.965.965,0,0,0-1.333,0L12.309,3.12a.657.657,0,0,1-1.122-.465V.942A.943.943,0,0,0,10.244,0H8.061a.943.943,0,0,0-.942.942V2.655A.657.657,0,0,1,6,3.12L4.786,1.909a.965.965,0,0,0-1.333,0L1.909,3.453a.943.943,0,0,0,0,1.333L3.12,6a.658.658,0,0,1-.465,1.122H.942A.943.943,0,0,0,0,8.061v2.183a.943.943,0,0,0,.942.943H2.655a.658.658,0,0,1,.465,1.122L1.909,13.52a.943.943,0,0,0,0,1.333L3.453,16.4a.964.964,0,0,0,1.333,0L6,15.186a.658.658,0,0,1,1.122.465v1.713a.943.943,0,0,0,.942.942h2.183a.943.943,0,0,0,.942-.942V15.651a.658.658,0,0,1,1.122-.465L13.519,16.4a.965.965,0,0,0,1.333,0L16.4,14.853a.943.943,0,0,0,0-1.333l-1.211-1.211a.658.658,0,0,1,.465-1.122h1.713a.943.943,0,0,0,.942-.942V8.061A.943.943,0,0,0,17.363,7.119Zm.264,3.126a.265.265,0,0,1-.264.264H15.651a1.336,1.336,0,0,0-.944,2.28L15.917,14a.265.265,0,0,1,0,.374l-1.543,1.543a.265.265,0,0,1-.374,0l-1.211-1.211a1.336,1.336,0,0,0-2.28.944v1.713a.265.265,0,0,1-.264.264H8.061a.265.265,0,0,1-.264-.264V15.651a1.315,1.315,0,0,0-.824-1.234,1.351,1.351,0,0,0-.52-.105,1.317,1.317,0,0,0-.936.395L4.306,15.917a.265.265,0,0,1-.374,0L2.389,14.373a.265.265,0,0,1,0-.374L3.6,12.789a1.335,1.335,0,0,0-.944-2.28H.942a.265.265,0,0,1-.264-.264V8.061A.265.265,0,0,1,.942,7.8H2.655A1.336,1.336,0,0,0,3.6,5.517L2.389,4.306a.265.265,0,0,1,0-.374L3.932,2.389a.265.265,0,0,1,.374,0L5.517,3.6A1.336,1.336,0,0,0,7.8,2.655V.942A.265.265,0,0,1,8.061.678h2.183a.265.265,0,0,1,.265.264V2.655a1.336,1.336,0,0,0,2.28.944L14,2.389a.265.265,0,0,1,.374,0l1.543,1.543a.265.265,0,0,1,0,.374L14.706,5.517a1.336,1.336,0,0,0,.944,2.28h1.713a.264.264,0,0,1,.264.264Z" transform="translate(0 0)" stroke="#000" stroke-width="0.4"/>
    <path id="Path_664" data-name="Path 664" d="M21.051,18A3.051,3.051,0,1,0,24.1,21.051,3.054,3.054,0,0,0,21.051,18Zm0,5.424a2.373,2.373,0,1,1,2.373-2.373A2.376,2.376,0,0,1,21.051,23.424Z" transform="translate(-11.898 -11.898)" stroke="#000" stroke-width="0.3"/>
  </g>
`,
];

export const ltService = [
  "21.14 19.03",
  `
  <path id="Icon_feather-folder" data-name="Icon feather-folder" d="M20.14,18.212a1.714,1.714,0,0,1-1.714,1.714H4.714A1.714,1.714,0,0,1,3,18.212v-12A1.714,1.714,0,0,1,4.714,4.5H9l1.714,2.571h7.713A1.714,1.714,0,0,1,20.14,8.785Z" transform="translate(-2.5 -4)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
`,
];

// menu side bar viplant

export const ltSite = [
  "22.5 16.69",
  `
  <g id="Group_4019" data-name="Group 4019" transform="translate(36.838 10.831)">
    <path id="Rectangle_19861" data-name="Rectangle 19861" d="M8.439,0h0a8.439,8.439,0,0,1,8.439,8.439v7.028a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8.439A8.439,8.439,0,0,1,8.439,0Z" transform="translate(-36.238 -10.231)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_3214" data-name="Path 3214" d="M-23.8,5.236h11.878V-1.792a8.439,8.439,0,0,0-8.439-8.439h-4.145" transform="translate(-3)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1"/>
    <line id="Line_424" data-name="Line 424" y2="4.986" transform="translate(-27.799 -0.197)" fill="none" stroke="#2b4d52" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_3215" data-name="Path 3215" d="M-24.9.531A3.035,3.035,0,0,1-26.843,2.27a3.152,3.152,0,0,1-4.1-3,6.476,6.476,0,0,1,1.676-3.66,1.938,1.938,0,0,1,3-.019C-25.226-3.149-24.166-1.284-24.9.531Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </g>
`,
];

// sidebar digidelta

export const ltMachine1 = [
  "21.51 17.73",
  `
  <g id="Group_4020" data-name="Group 4020" transform="translate(-420.712 -551.967)">
    <g id="Path_528" data-name="Path 528" transform="translate(424.966 561.69)">
      <path id="Path_2435" data-name="Path 2435" d="M436.556,568.456H424.929V561.6h11.627Z" transform="translate(-424.929 -561.599)" fill="none" stroke="#080909" stroke-width="1"/>
    </g>
    <g id="Path_528-2" data-name="Path 528-2" transform="translate(424.966 552.467)">
      <path id="Path_2435-2" data-name="Path 2435-2" d="M436.556,558.1H424.929v-4.271h11.627Z" transform="translate(-424.929 -553.833)" fill="none" stroke="#080909" stroke-width="1"/>
    </g>
    <path id="Path_3300" data-name="Path 3300" d="M424.844,565.464h-2.26a1.372,1.372,0,0,1-1.372-1.372v-4.977a1.372,1.372,0,0,1,1.372-1.373h16.767a1.372,1.372,0,0,1,1.372,1.373v4.977a1.372,1.372,0,0,1-1.372,1.372h-2.7" transform="translate(0 -0.957)" fill="none" stroke="#030504" stroke-width="1"/>
  </g>
`,
];

export const ltRawMaterials = [
  "21 20.89",
  `
  <g id="Group_4022" data-name="Group 4022" transform="translate(-511.572 -604.189)">
    <line id="Line_501" data-name="Line 501" x2="6.866" transform="translate(518.457 618.59)" fill="none" stroke="#030504" stroke-width="0.8"/>
    <line id="Line_502" data-name="Line 502" x2="11.997" transform="translate(515.964 616.949)" fill="none" stroke="#030504" stroke-width="0.8"/>
    <line id="Line_503" data-name="Line 503" x2="5.561" transform="translate(524.971 615.307)" fill="none" stroke="#030504" stroke-width="0.8"/>
    <line id="Line_503-2" data-name="Line 503" x2="5.738" transform="translate(513.394 615.307)" fill="none" stroke="#030504" stroke-width="0.8"/>
    <path id="Path_3301" data-name="Path 3301" d="M514.73,616.235l-2.564,1.61a.2.2,0,0,0,0,.339l9.636,6.049a.2.2,0,0,0,.213,0l9.636-6.049a.2.2,0,0,0,0-.339l-2.644-1.66" fill="none" stroke="#030504" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_3302" data-name="Path 3302" d="M514.838,612.634l-2.672,1.677a.2.2,0,0,0,0,.339l9.636,6.05a.2.2,0,0,0,.213,0l9.636-6.05a.2.2,0,0,0,0-.339l-2.664-1.672" fill="none" stroke="#030504" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_3303" data-name="Path 3303" d="M531.651,610.778l-9.636-6.05a.2.2,0,0,0-.213,0l-9.636,6.05a.2.2,0,0,0,0,.339l9.636,6.049a.2.2,0,0,0,.213,0l9.636-6.049A.2.2,0,0,0,531.651,610.778Z" fill="none" stroke="#030504" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_3304" data-name="Path 3304" d="M514.653,616.21" fill="none" stroke="#030504" stroke-miterlimit="10" stroke-width="1"/>
    <path id="Path_3305" data-name="Path 3305" d="M514.653,616.21" fill="none" stroke="#030504" stroke-miterlimit="10" stroke-width="1"/>
  </g>
`,
];

export const ltPlanning = [
  "19 19",
  `
  <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" fill="#fff" opacity="0"/>
  <g id="Group_4040" data-name="Group 4040" transform="translate(-738.228 -622.129)">
    <rect id="Rectangle_20101" data-name="Rectangle 20101" width="17.756" height="13.046" rx="0.3" transform="translate(738.857 626.204)" fill="none" stroke="#030504" stroke-width="1"/>
    <g id="Group_4038" data-name="Group 4038" transform="translate(743.708 623.902)">
      <line id="Line_527" data-name="Line 527" y2="4.413" fill="#5d5d5d" stroke="#030504" stroke-width="1"/>
      <line id="Line_528" data-name="Line 528" y2="4.413" transform="translate(8.054)" fill="#5d5d5d" stroke="#030504" stroke-width="1"/>
    </g>
    <g id="Group_4039" data-name="Group 4039" transform="translate(742.476 630.147)">
      <rect id="Rectangle_16924" data-name="Rectangle 16924" width="1.108" height="1.108" fill="#030504"/>
      <rect id="Rectangle_16925" data-name="Rectangle 16925" width="1.108" height="1.108" transform="translate(3.234)" fill="#030504"/>
      <rect id="Rectangle_16926" data-name="Rectangle 16926" width="1.108" height="1.108" transform="translate(6.175)" fill="#030504"/>
      <rect id="Rectangle_16927" data-name="Rectangle 16927" width="1.108" height="1.108" transform="translate(9.41)" fill="#030504"/>
      <rect id="Rectangle_16928" data-name="Rectangle 16928" width="1.108" height="1.108" transform="translate(0 2.648)" fill="#030504"/>
      <rect id="Rectangle_16929" data-name="Rectangle 16929" width="1.108" height="1.108" transform="translate(3.234 2.648)" fill="#030504"/>
      <rect id="Rectangle_16930" data-name="Rectangle 16930" width="1.108" height="1.108" transform="translate(6.175 2.648)" fill="#030504"/>
      <rect id="Rectangle_16931" data-name="Rectangle 16931" width="1.108" height="1.108" transform="translate(9.41 2.648)" fill="#030504"/>
      <rect id="Rectangle_16932" data-name="Rectangle 16932" width="1.108" height="1.108" transform="translate(0 5.295)" fill="#030504"/>
      <rect id="Rectangle_16933" data-name="Rectangle 16933" width="1.108" height="1.108" transform="translate(3.234 5.295)" fill="#030504"/>
      <rect id="Rectangle_16934" data-name="Rectangle 16934" width="1.108" height="1.108" transform="translate(6.175 5.295)" fill="#030504"/>
      <rect id="Rectangle_16935" data-name="Rectangle 16935" width="1.108" height="1.108" transform="translate(9.41 5.295)" fill="#030504"/>
    </g>
  </g>
`,
];

export const ltRecipe = [
  "16.052 19.151",
  `
  <g id="Group_3822" data-name="Group 3822" transform="translate(3373.65 4711.875)">
    <g id="Group_3688" data-name="Group 3688" transform="translate(-3373 -4711.225)">
      <path id="Rectangle_19892" data-name="Rectangle 19892" d="M2.258,0H14.752a0,0,0,0,1,0,0V4.515a0,0,0,0,1,0,0H2.258A2.258,2.258,0,0,1,0,2.258v0A2.258,2.258,0,0,1,2.258,0Z" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_3216" data-name="Path 3216" d="M-1094.727-146.533v15.816h-12.719a2.033,2.033,0,0,1-2.033-2.033v-13.783" transform="translate(1109.479 148.568)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
    <line id="Line_472" data-name="Line 472" x2="7.898" transform="translate(-3369.242 -4697.515)" fill="none" stroke="#000" stroke-linecap="square" stroke-width="1"/>
    <line id="Line_473" data-name="Line 473" x2="7.898" transform="translate(-3369.242 -4700.674)" fill="none" stroke="#000" stroke-linecap="square" stroke-width="1"/>
  </g>
`,
];

export const ltMaintenace = [
  "19 19",
  `
  <rect id="Rectangle_20102" data-name="Rectangle 20102" width="19" height="19" fill="#fff" opacity="0"/>
  <g id="Group_4048" data-name="Group 4048" transform="translate(-581.406 -613.742)">
    <path id="Path_3287" data-name="Path 3287" d="M596.279,614.476H593.4l-.983,1.472h-.8l-.511-.578h-1.126v2.711h1.24l.227-.563h.647a2.168,2.168,0,0,1,1.579,1.94H596.5l.5-1.727c.712-1.955,2.732.23,2.732.23l.123-.079A4.06,4.06,0,0,0,596.279,614.476Z" fill="none" stroke="#080909" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <line id="Rectangle_20058" data-name="Rectangle 20058" y2="3.493" transform="translate(595.11 619.821)" fill="none" stroke="#080909" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <g id="Group_3847" data-name="Group 3847">
      <path id="Path_3288" data-name="Path 3288" d="M597.1,632.169a1,1,0,0,0-.623-.918v-6.837c-.035-.752-.5-.776-.5-.776h-1.811s-.465.024-.5.776v6.837a1,1,0,0,0-.623.918Z" fill="none" stroke="#080909" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
    <path id="Path_3289" data-name="Path 3289" d="M585.708,614.315v2.46h-2.136v-2.46a2.681,2.681,0,1,0,2.137,0Z" fill="none" stroke="#080909" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <line id="Rectangle_20059" data-name="Rectangle 20059" y1="3.115" transform="translate(584.607 619.728)" fill="none" stroke="#080909" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_3290" data-name="Path 3290" d="M585.653,623.671v7.7c0,.892-1.012.791-1.012.791-1.011.017-1.011-.791-1.011-.791v-7.7s.03-.752,1.011-.727C584.641,622.941,585.542,622.871,585.653,623.671Z" fill="none" stroke="#080909" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </g>

`,
];

export const ltQuality = [
  "19 19",
  `
  <g id="Ellipse_442" data-name="Ellipse 442" fill="#fff" stroke="#000" stroke-width="1">
    <circle cx="9.5" cy="9.5" r="9.5" stroke="none"/>
    <circle cx="9.5" cy="9.5" r="9" fill="none"/>
  </g>
  <path id="Checkbox" d="M4.146,7.211,0,3.065l.841-.841L4.146,5.468,9.615,0l.841.841Z" transform="translate(4.497 6.408)"/>
`,
];

export const ltEbr = [
  "19 19",
  `
  <g id="Group_4037" data-name="Group 4037" transform="translate(-3201 93)">
    <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" transform="translate(3201 -93)" fill="#fff" opacity="0"/>
    <g id="Group_4037-2" data-name="Group 4037" transform="translate(2512.881 -721.215)">
      <line id="Line_527" data-name="Line 527" x2="19" transform="translate(688.119 646.686)" fill="none" stroke="#000" stroke-width="1"/>
      <g id="Rectangle_20102" data-name="Rectangle 20102">
        <path id="Path_3308" data-name="Path 3308" d="M690.3,646.245V634.833a.5.5,0,0,1,.5-.5H695.5a.5.5,0,0,1,.5.5v11.412" fill="none" stroke="#000" stroke-width="1"/>
      </g>
      <g id="Rectangle_20103" data-name="Rectangle 20103">
        <path id="Path_3309" data-name="Path 3309" d="M699.237,646.245v-17a.5.5,0,0,1,.5-.5h4.706a.5.5,0,0,1,.5.5v17" fill="none" stroke="#000" stroke-width="1"/>
      </g>
    </g>
  </g>
`,
];

export const ltPerformance = [
  "19 19",
  `
  <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" fill="#fff" opacity="0"/>
  <g id="Group_4034" data-name="Group 4034" transform="translate(-648.157 -604.289)">
    <path id="Path_3306" data-name="Path 3306" d="M648.678,619.436l4.487-4.487,3.333,3.333,10.127-10.127" fill="none" stroke="#030504" stroke-miterlimit="10" stroke-width="1.1"/>
    <path id="Path_3307" data-name="Path 3307" d="M666.636,612v-3.853h-3.853" fill="none" stroke="#030504" stroke-miterlimit="10" stroke-width="1.1"/>
  </g>
`,
];

export const ltReporting = [
  "19 19",
  `
  <defs>
    <clipPath id="clip">
      <use xlink:href="#fill"/>
    </clipPath>
  </defs>
  <g id="Group_4026" data-name="Group 4026" transform="translate(-3350.932 93)">
    <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" transform="translate(3351 -93)" fill="#fff" opacity="0"/>
    <g id="Group_4025" data-name="Group 4025" transform="translate(-29.568 -18.831)">
      <path id="Icon_material-report-problem" data-name="Icon material-report-problem" d="M1.5,18.662H19.635l-4.524-7.815L10.568,3Z" transform="translate(3379.5 -75.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <g id="Rectangle_20099" data-name="Rectangle 20099" transform="translate(3389.579 -67.2)" stroke="#000" stroke-width="1">
        <rect id="fill" width="0.978" height="5.782" stroke="none"/>
        <path d="M0,0.5h0.9775390625M0.5,0v5.781654357910156M0.9775390625,5.281654357910156h-0.9775390625M0.4775390625,5.781654357910156v-5.781654357910156" fill="none" clip-path="url(#clip)"/>
      </g>
    </g>
    <g id="Rectangle_20100" data-name="Rectangle 20100" transform="translate(3360.011 -79.286)" stroke="#000" stroke-width="1">
      <rect width="1" height="1" stroke="none"/>
      <rect x="0.5" y="0.5" fill="none"/>
    </g>
  </g>

`,
];

export const ltCalculations = [
  "19 19",
  `
  <g id="Group_4029" data-name="Group 4029" transform="translate(-3318 93.001)">
    <g id="Group_4027" data-name="Group 4027" transform="translate(-33)">
      <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" transform="translate(3351 -93)" fill="#fff" opacity="0"/>
      <g id="Group_4028" data-name="Group 4028" transform="translate(2072 -19006.945)">
        <g id="Group_3817" data-name="Group 3817" transform="translate(1279 18913.945)">
          <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0 -0.001)" fill="none" stroke="#000" stroke-width="1">
            <rect width="19" height="19" rx="2" stroke="none"/>
            <rect x="0.5" y="0.5" width="18" height="18" rx="1.5" fill="none"/>
          </g>
          <g id="Group_3821" data-name="Group 3821" transform="translate(10.681 11.612)">
            <path id="Union_35" data-name="Union 35" d="M4.785,0V.667H0V0Z" transform="translate(0 0)" stroke="#000" stroke-width="0.3"/>
            <path id="Union_36" data-name="Union 36" d="M4.785,0V.667H0V0Z" transform="translate(0 2.627)" stroke="#000" stroke-width="0.3"/>
          </g>
          <path id="Union_37" data-name="Union 37" d="M4.785,0V.667H0V0Z" transform="translate(10.681 5.472)" stroke="#000" stroke-width="0.3"/>
          <g id="Group_3822" data-name="Group 3822" transform="translate(3.292 3.412)">
            <path id="Union_41" data-name="Union 41" d="M4.785,0V.667H0V0Z" transform="translate(0 2.06)" stroke="#000" stroke-width="0.3"/>
            <path id="Union_42" data-name="Union 42" d="M4.785,0V.667H0V0Z" transform="translate(2.727 0) rotate(90)" stroke="#000" stroke-width="0.3"/>
          </g>
          <g id="Group_3823" data-name="Group 3823" transform="translate(5.685 9.874) rotate(45)">
            <path id="Union_41-2" data-name="Union 41" d="M4.785,0V.667H0V0Z" transform="translate(0 2.06)" stroke="#000" stroke-width="0.3"/>
            <path id="Union_42-2" data-name="Union 42" d="M4.785,0V.667H0V0Z" transform="translate(2.727 0) rotate(90)" stroke="#000" stroke-width="0.3"/>
          </g>
        </g>
      </g>
    </g>
  </g>
`,
];

//////////////

export const ltDropdown = [
  "24 24",
  `
  <title>dropdown</title>
  <g id="Group_3648" data-name="Group 3648" transform="translate(-1299 -199)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1299 199)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="24" height="24" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="22.7" rx="2.35" fill="none"/>
    </g>
    <path id="Icon_material-keyboard-arrow-down" data-name="Icon material-keyboard-arrow-down" d="M8.2,10.489l4.337,3.987,4.319-3.987.938.939L12.54,16.36,7.265,11.428Z" transform="translate(1298.457 198.381)" stroke="#000" stroke-width="0.2"/>
  </g>
`,
];

export const ltFileupload = [
  "24 24",
  `
  <title>file upload</title>
  <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(-3.85 -5.686)">
    <path id="Path_3214" data-name="Path 3214" d="M27.765,22.5v5.17a2.585,2.585,0,0,1-2.585,2.585H7.085A2.585,2.585,0,0,1,4.5,27.67V22.5" transform="translate(0 -4.709)" fill="none" stroke="#000" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3215" data-name="Path 3215" d="M19.8,9.151,15.151,4.5,10.5,9.151" transform="translate(0.982 1.836)" fill="none" stroke="#000" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3216" data-name="Path 3216" d="M18,4.5V14.528" transform="translate(-1.867 2.665)" fill="none" stroke="#000" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`,
];

export const ltGroup = [
  "26 26",
  `
  <g id="Group_3646" data-name="Group 3646" transform="translate(-1298 -198)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1299 199)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="24" height="24" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="22.7" rx="2.35" fill="none"/>
    </g>
    <g id="Ellipse_424" data-name="Ellipse 424" transform="translate(1298 198)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_427" data-name="Ellipse 427" transform="translate(1298 219)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_425" data-name="Ellipse 425" transform="translate(1319 198)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_426" data-name="Ellipse 426" transform="translate(1319 219)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
  </g>
`,
];

export const ltSection = [
  "26 26",
  `
  <g id="Group_3646" data-name="Group 3646" transform="translate(-1298 -198)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1299 199)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="24" height="24" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="22.7" rx="2.35" fill="none"/>
    </g>
    <g id="Ellipse_424" data-name="Ellipse 424" transform="translate(1298 198)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_427" data-name="Ellipse 427" transform="translate(1298 219)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_425" data-name="Ellipse 425" transform="translate(1319 198)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
    <g id="Ellipse_426" data-name="Ellipse 426" transform="translate(1319 219)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
    </g>
  </g>
`,
];

export const ltText = [
  "24 24",
  `
  <g id="Group_3771" data-name="Group 3771" transform="translate(-1449 -341)">
    <g id="Group_3647" data-name="Group 3647" transform="translate(1448.686 341)">
      <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0.315)" fill="#fff" stroke="#000" stroke-width="1.3">
        <rect width="23" height="23" rx="3" stroke="none"/>
        <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
      </g>
    </g>
    <path id="Icon_material-title" data-name="Icon material-title" d="M7.5,6V8.056h3.769v8.223h2.056V8.056h3.769V6Z" transform="translate(1445.5 339)"/>
    <line id="Line_469" data-name="Line 469" x2="15.533" transform="translate(1452.5 358.5)" fill="none" stroke="#000" stroke-width="1.3"/>
  </g>
`,
];

export const ltSignature = [
  "24 24",
  `
  <g id="Group_3648" data-name="Group 3648" transform="translate(-0.314)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0.314)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="23" height="23" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
    </g>
    <path id="Icon_awesome-signature" data-name="Icon awesome-signature" d="M13.257,7.222c-1.1.074-2.674,1.163-3.469,1.521a4.4,4.4,0,0,1-1.619.519c-.481,0-.553-.345-.453-1.1.023-.17.249-1.685-.908-1.619-.534.032-1.368.528-3.605,2.68l.883-2.206A1.838,1.838,0,0,0,1.326,4.827L.159,5.612a.342.342,0,0,0-.1.47l.366.574a.338.338,0,0,0,.47.1l1.234-.827a.475.475,0,0,1,.7.576L.731,11.733a.68.68,0,0,0,.632.934.673.673,0,0,0,.481-.2c.9-.9,3.291-3.205,4.492-4.165a2.208,2.208,0,0,0,.438,1.782,1.8,1.8,0,0,0,1.393.538,5.379,5.379,0,0,0,2.176-.638c.7-.315,2.106-1.332,2.944-1.4a.341.341,0,0,0,.323-.336V7.566a.337.337,0,0,0-.353-.345Z" transform="translate(5.313 2.501)"/>
  </g>
`,
];

export const ltNumeric = [
  "24 24",
  `
  <g id="Group_3733" data-name="Group 3733" transform="translate(-1452 -520)">
    <g id="Group_3722" data-name="Group 3722" transform="translate(1451.686 520)">
      <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0.315)" fill="#fff" stroke="#000" stroke-width="1.3">
        <rect width="23" height="23" rx="3" stroke="none"/>
        <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
      </g>
    </g>
    <text id="_1" data-name="1" transform="translate(1456 535)" font-size="15" font-family="Avenir-Black, Avenir" font-weight="800" letter-spacing="0.073em"><tspan x="0" y="0">1</tspan></text>
    <g id="Group_3724" data-name="Group 3724" transform="translate(0 21.25)">
      <line id="Line_470" data-name="Line 470" x2="15.533" transform="translate(1455.5 517.25)" fill="none" stroke="#000" stroke-width="1.3"/>
    </g>
  </g>
`,
];

export const ltNumber = [
  "24 24",
  `
  <g id="Group_3733" data-name="Group 3733" transform="translate(-1452 -520)">
    <g id="Group_3722" data-name="Group 3722" transform="translate(1451.686 520)">
      <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0.315)" fill="#fff" stroke="#000" stroke-width="1.3">
        <rect width="23" height="23" rx="3" stroke="none"/>
        <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
      </g>
    </g>
    <text id="_1" data-name="1" transform="translate(1456 535)" font-size="15" font-family="Avenir-Black, Avenir" font-weight="800" letter-spacing="0.073em"><tspan x="0" y="0">1</tspan></text>
    <g id="Group_3724" data-name="Group 3724" transform="translate(0 21.25)">
      <line id="Line_470" data-name="Line 470" x2="15.533" transform="translate(1455.5 517.25)" fill="none" stroke="#000" stroke-width="1.3"/>
    </g>
  </g>
`,
];

export const ltSwitch = [
  "24 24",
  `
  <g id="Group_2103" data-name="Group 2103" transform="translate(0 -0.011)">
    <g id="Rectangle_17656" data-name="Rectangle 17656" transform="translate(0 2.01)" fill="none" stroke="#000" stroke-width="1.3">
      <rect width="23.124" height="10.837" rx="5.418" stroke="none"/>
      <rect x="0.65" y="0.65" width="21.824" height="9.537" rx="4.768" fill="none"/>
    </g>
    <g id="Ellipse_357" data-name="Ellipse 357" transform="translate(0 0.011)" fill="#fff" stroke="#000" stroke-width="1.3">
      <circle cx="7.354" cy="7.354" r="7.354" stroke="none"/>
      <circle cx="7.354" cy="7.354" r="6.704" fill="none"/>
    </g>
  </g>
`,
];

export const ltChecklist = [
  "24 24",
  `
  <g id="Group_3797" data-name="Group 3797" transform="translate(-0.314)">
    <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0.314)" fill="#fff" stroke="#000" stroke-width="1.3">
      <rect width="23" height="23" rx="3" stroke="none"/>
      <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
    </g>
    <g id="Widgets_Table_Table_Header" data-name="Widgets / Table / Table Header" transform="translate(11.753 2.246) rotate(45)">
      <g id="_" data-name="+">
        <path id="Union_1" data-name="Union 1" d="M5.688,13V7.313H0V5.687H5.688V0H7.313V5.687H13V7.313H7.313V13Z" transform="translate(0.088 0.001)"/>
      </g>
    </g>
  </g>
`,
];

export const ltTitle = [
  "24 24",
  `
  <g id="Group_3723" data-name="Group 3723" transform="translate(-0.315)">
     <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0.315)" fill="#fff" stroke="#000" stroke-width="1.3">
       <rect width="23" height="23" rx="3" stroke="none"/>
       <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
     </g>
-    <path id="Icon_material-title" data-name="Icon material-title" d="M7.5,6V8.061h3.779v8.245h2.061V8.061H17.12V6Z" transform="translate(-0.766 1)"/>
+    <g id="Ellipse_424" data-name="Ellipse 424" transform="translate(0.277)" fill="#fff" stroke="#000" stroke-width="1.3">
+      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
+      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
+    </g>
+    <g id="Ellipse_427" data-name="Ellipse 427" transform="translate(0.277 21)" fill="#fff" stroke="#000" stroke-width="1.3">
+      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
+      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
+    </g>
+    <g id="Ellipse_425" data-name="Ellipse 425" transform="translate(20.277)" fill="#fff" stroke="#000" stroke-width="1.3">
+      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
+      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
+    </g>
+    <g id="Ellipse_426" data-name="Ellipse 426" transform="translate(20.277 21)" fill="#fff" stroke="#000" stroke-width="1.3">
+      <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
+      <circle cx="2.5" cy="2.5" r="1.85" fill="none"/>
+    </g>
   </g>
`,
];

export const ltDate = [
  "24 24",
  `
  <g id="Group_3795" data-name="Group 3795" transform="translate(-1449 -574.838)">
    <g id="Group_3790" data-name="Group 3790" transform="translate(1448.685 576)">
      <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(0.315)" fill="#fff" stroke="#000" stroke-width="1.3">
        <rect width="22" height="22" rx="3" stroke="none"/>
        <rect x="0.65" y="0.65" width="20.7" height="20.7" rx="2.35" fill="none"/>
      </g>
      <g id="Group_3793" data-name="Group 3793" transform="translate(-1454.685 -510.75)">
        <line id="Line_470" data-name="Line 470" x2="20.925" transform="translate(1455.5 517.25)" fill="none" stroke="#000" stroke-width="1.3"/>
      </g>
      <g id="Group_3794" data-name="Group 3794" transform="translate(-1454.685 -510.75)">
        <line id="Line_470-2" data-name="Line 470" x2="20.925" transform="translate(1455.5 517.25)" fill="none" stroke="#000" stroke-width="1.3"/>
      </g>
    </g>
    <g id="Group_3791" data-name="Group 3791" transform="translate(1455.163 574.838) rotate(90)">
      <line id="Line_470-3" data-name="Line 470" x2="4.444" fill="none" stroke="#000" stroke-width="1.3"/>
    </g>
    <g id="Group_3792" data-name="Group 3792" transform="translate(1465 574.838) rotate(90)">
      <line id="Line_470-4" data-name="Line 470" x2="4.444" fill="none" stroke="#000" stroke-width="1.3"/>
    </g>
    <text id="_11" data-name="11" transform="translate(1453 594)" font-size="11" font-family="Avenir-Black, Avenir" font-weight="800" letter-spacing="0.073em"><tspan x="0" y="0">11</tspan></text>
  </g>
`,
];

export const ltUserinput = [
  "24 24",
  `
  <g>
  <title>Layer 1</title>
  <g data-name="Group 3663" id="Group_3663">
  <g data-name="Group 3662" id="Group_3662">
    <g stroke-width="1.3" stroke="#000" fill="#fff" data-name="Rectangle 19784" id="Rectangle_19784">
    <rect id="svg_1" x="0" y="0" rx="3" height="24" width="24"/>
    <rect id="svg_2" fill="none" rx="2.35" height="22.7" width="22.7" y="0.65" x="0.65"/>
    </g>
  </g>
  <path d="m15.19895,6.89799l0,-1a0.3,0.3 0 0 0 -0.3,-0.3a3.718,3.718 0 0 0 -2.9,0.949a3.712,3.712 0 0 0 -2.9,-0.949a0.3,0.3 0 0 0 -0.3,0.3l0,0.991a0.3,0.3 0 0 0 0.3,0.3c0.649,-0.008 2.1,0.087 2.1,1.211c0.03,0.017 0.009,7.213 0,7.2c0,1.124 -1.4,1.2 -2.1,1.2a0.3,0.3 0 0 0 -0.3,0.3l0,1a0.3,0.3 0 0 0 0.3,0.3a3.718,3.718 0 0 0 2.9,-0.948a3.712,3.712 0 0 0 2.9,0.946a0.3,0.3 0 0 0 0.3,-0.3l0,-0.991a0.3,0.3 0 0 0 -0.3,-0.3c-0.651,0.008 -2.1,-0.082 -2.1,-1.209c-0.038,0.022 0.007,-7.194 0,-7.2c0,-1.118 1.4,-1.2 2.1,-1.2a0.3,0.3 0 0 0 0.3,-0.3z" data-name="Icon awesome-i-cursor" id="Icon_awesome-i-cursor"/>
  </g>
  </g>
`,
];

// viplant
export const ltTemperature = [
  "11.612 26.077",
  `
  <g id="Group_3243" data-name="Group 3243" transform="translate(-762.672 -323.098)">
    <g id="Solid" transform="translate(762.893 323.248)">
      <path id="Path_3189" data-name="Path 3189" d="M24.585,27.777a5.582,5.582,0,0,1-3.007-10.286V5.007a3.007,3.007,0,0,1,6.015,0V17.491a5.582,5.582,0,0,1-3.007,10.286Zm0-24.918a2.151,2.151,0,0,0-2.148,2.148V17.732a.43.43,0,0,1-.215.372,4.726,4.726,0,1,0,4.726,0,.43.43,0,0,1-.215-.372V5.007a2.151,2.151,0,0,0-2.148-2.148Z" transform="translate(-18.999 -2)" fill="var(--ci-primary-color, currentColor)" stroke="var(--ci-primary-color, currentColor)" stroke-width="0.3"/>
    </g>
    <path id="Path_3204" data-name="Path 3204" d="M0,0H3.139" transform="translate(765.901 330.816)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.2"/>
    <path id="Path_3205" data-name="Path 3205" d="M0,0H3.139" transform="translate(765.901 333.606)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.2"/>
    <path id="Path_3206" data-name="Path 3206" d="M0,0H3.139" transform="translate(765.901 336.395)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.2"/>
  </g>
`,
];

export const ltHumidity = [
  "18.767 22.713",
  `
  <g id="humidade" transform="translate(-284.4 -179.643)">
    <g id="Humidity" transform="translate(285 184.878)">
      <g id="Group_3231" data-name="Group 3231" transform="translate(0 0)">
        <path id="Path_3188" data-name="Path 3188" d="M70.091,44.033a5.833,5.833,0,0,1-4.164,1.71,5.949,5.949,0,0,1-4.164-1.71,5.833,5.833,0,0,1-1.71-4.164c0-2.317,3.116-6.922,5.185-9.983.248-.359.469-.717.689-1.02.221.3.441.662.689,1.02,2.068,3.089,5.185,7.667,5.185,9.983A5.949,5.949,0,0,1,70.091,44.033Z" transform="translate(-60.052 -28.865)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      </g>
    </g>
    <g id="Humidity-2" data-name="Humidity" transform="translate(295.775 180.243)">
      <g id="Group_3231-2" data-name="Group 3231" transform="translate(0 0)">
        <path id="Path_3188-2" data-name="Path 3188" d="M65.855,37.633a3.372,3.372,0,0,1-2.407.988,3.439,3.439,0,0,1-2.407-.988,3.372,3.372,0,0,1-.988-2.407c0-1.339,1.8-4,3-5.771.143-.207.271-.414.4-.59.128.175.255.383.4.59,1.2,1.786,3,4.432,3,5.771A3.439,3.439,0,0,1,65.855,37.633Z" transform="translate(-60.052 -28.865)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
      </g>
    </g>
  </g>
`,
];

export const ltBurner = [
  "25.938 24.325",
  `
  <g id="Queimador" transform="translate(0.6 0.6)">
    <path id="Path_3196" data-name="Path 3196" d="M2.9,353.727H1.933A1.933,1.933,0,0,1,0,351.794v-.241a1.933,1.933,0,0,1,1.933-1.933h3.1" transform="translate(0 -333.539)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3197" data-name="Path 3197" d="M65.941,393.51,59,396.174a1.933,1.933,0,0,1-2.5-1.111l-.086-.225a1.933,1.933,0,0,1,1.111-2.5l2.761-1.061" transform="translate(-53.572 -373.186)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3198" data-name="Path 3198" d="M406.755,349.621h3.152a1.933,1.933,0,0,1,1.933,1.933v.241a1.933,1.933,0,0,1-1.933,1.933h-.966" transform="translate(-387.102 -333.539)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3199" data-name="Path 3199" d="M256,393.739l6.937,2.664a1.933,1.933,0,0,0,2.5-1.111l.086-.225a1.933,1.933,0,0,0-1.111-2.5l-2.739-1.049" transform="translate(-243.631 -373.415)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3200" data-name="Path 3200" d="M90.657,37.056a8.438,8.438,0,0,0,7.294-10.695L96.811,22.3h0l-2.12,2.213-4.16-6.631-.7-1.112h0l-4.857,7.743L82.856,22.3l-1.14,4.064A8.432,8.432,0,0,0,89.625,37.09l.209,0h0l.209,0,.143,0,.113,0C90.419,37.076,90.538,37.067,90.657,37.056Z" transform="translate(-77.465 -16.768)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
    <path id="Path_3201" data-name="Path 3201" d="M174.344,225.646q-.007-.19-.032-.382a4.449,4.449,0,0,0-.13-.642l-.59-2.1-1.1,1.146-2.515-4.008-2.515,4.008-1.1-1.146-.59,2.1a4.365,4.365,0,0,0,4.094,5.554h.216a4.344,4.344,0,0,0,.9-.11l.123-.03a4.248,4.248,0,0,0,.591-.2l.113-.05q.112-.052.22-.11.162-.087.316-.187a4.33,4.33,0,0,0,.484-.366q.091-.08.178-.164t.169-.174l.081-.09q.079-.091.154-.187a4.434,4.434,0,0,0,.507-.823q.026-.055.05-.11a4.367,4.367,0,0,0,.366-1.547Q174.351,225.837,174.344,225.646Z" transform="translate(-157.611 -209.853)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
  </g>
`,
];

export const ltMode = [
  "20.423 16.86",
  `
  <g id="Group_3556" data-name="Group 3556" transform="translate(36.889 45.034)">
    <rect id="Rectangle_19864" data-name="Rectangle 19864" width="19.123" height="15.56" transform="translate(-36.239 -44.384)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <g id="Group_3557" data-name="Group 3557">
      <path id="Path_3233" data-name="Path 3233" d="M0,0H7.662" transform="translate(-28.183 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <path id="Path_3234" data-name="Path 3234" d="M0,0H1.274" transform="translate(-33.001 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <circle id="Ellipse_425" data-name="Ellipse 425" cx="1.507" cy="1.507" r="1.507" transform="translate(-31.727 -40.888)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
    </g>
    <g id="Group_3558" data-name="Group 3558" transform="translate(-53.521 -72.763) rotate(180)">
      <path id="Path_3233-2" data-name="Path 3233" d="M0,0H7.662" transform="translate(-28.183 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <path id="Path_3234-2" data-name="Path 3234" d="M0,0H1.274" transform="translate(-33.001 -39.345)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3"/>
      <circle id="Ellipse_425-2" data-name="Ellipse 425" cx="1.507" cy="1.507" r="1.507" transform="translate(-31.727 -40.888)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
    </g>
  </g>
`,
];

export const ltWindowside = [
  "19.857 18.365",
  `
  <g id="janela_lateral" data-name="janela lateral" transform="translate(100.268 20.672)">
    <path id="Path_3229" data-name="Path 3229" d="M-81.061-20.022V-2.957l-18.557-2V-18.022Z" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_425" data-name="Line 425" y1="14.531" transform="translate(-91.905 -18.817)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_426" data-name="Line 426" x2="18.063" transform="translate(-99.275 -11.577)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`,
];

export const ltWindowtop = [
  "18.365 19.857",
  `
  <g id="janela_topo" data-name="janela topo" transform="translate(127.345 21.418)">
    <path id="Path_3230" data-name="Path 3230" d="M-109.63-2.211H-126.7l2-18.557h13.065Z" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_427" data-name="Line 427" x2="14.531" transform="translate(-125.366 -13.055)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <line id="Line_428" data-name="Line 428" y2="18.063" transform="translate(-118.075 -20.424)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`,
];

export const ltShadow = [
  "22.808 20.201",
  `
  <g id="Tela" transform="translate(-285.097 -379.35)">
    <path id="Path_3231" data-name="Path 3231" d="M-117.239-49.081l10.679-7.251,10.321,7.251" transform="translate(403.239 436.332)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <g id="Group_3555" data-name="Group 3555" transform="translate(293.105 387.798)">
      <line id="Line_430" data-name="Line 430" y2="5.385" transform="translate(3.395 5.311)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.3"/>
      <path id="Path_3232" data-name="Path 3232" d="M-24.417.983a3.278,3.278,0,0,1-2.1,1.878A3.4,3.4,0,0,1-30.943-.381a6.994,6.994,0,0,1,1.81-3.953,2.093,2.093,0,0,1,3.242-.021C-24.768-2.992-23.623-.977-24.417.983Z" transform="translate(30.943 5.114)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    </g>
    <line id="Line_431" data-name="Line 431" x2="16.201" transform="translate(288.4 398.901)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-width="1.3" stroke-dasharray="3"/>
  </g>
`,
];

export const ltGraph = [
  "20.588 22.504",
  `
  <path id="Path_2532" data-name="Path 2532" d="M62.537,187.855H61.108V168.406a1.754,1.754,0,0,0-1.752-1.752H56.161a1.754,1.754,0,0,0-1.752,1.752v19.449H51.432V177.2a1.754,1.754,0,0,0-1.752-1.752H46.485a1.754,1.754,0,0,0-1.752,1.752v10.653c.075.033,2.963-.09-1.481,0a.652.652,0,1,0,0,1.3H62.537a.652.652,0,1,0,0-1.3Zm-12.408,0H46.037V177.2a.449.449,0,0,1,.448-.448H49.68a.449.449,0,0,1,.448.448Zm9.676,0H55.713V168.406a.449.449,0,0,1,.448-.448h3.195a.449.449,0,0,1,.448.448v19.449Z" transform="translate(-42.601 -166.654)"/>
`,
];

export const ltWindSpeed = [
  "21.041 20.913",
  `
  <path id="WindSpeed" d="M14.165,7.793a2.644,2.644,0,0,1,3.219-.434,2.7,2.7,0,0,1,1.241,3.03,2.668,2.668,0,0,1-2.583,1.988H10M22.122,25.72a2.644,2.644,0,0,0,3.219.434,2.7,2.7,0,0,0,1.241-3.03A2.668,2.668,0,0,0,24,21.135H10m14.015-10.12a3.305,3.305,0,0,1,4.016-.531,3.375,3.375,0,0,1,1.551,3.778,3.335,3.335,0,0,1-3.21,2.494H10" transform="translate(-9.35 -6.266)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" stroke="var(--ci-primary-color, currentColor)" />
`,
];

export const ltWindDir = [
  "20.627 16.223",
  `
  <path id="Path_3211" data-name="Path 3211" d="M455.453,134.418l-19.3,2.021-.032-11.934,19.327,2.025Z" transform="translate(-436.126 -122.37)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.3"/>
  <line id="Line_259" data-name="Line 259" y2="16.223" transform="translate(0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
  <line id="Line_262" data-name="Line 262" y2="10.042" transform="translate(13.28 3.088)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
  <line id="Line_260" data-name="Line 260" y2="10.573" transform="translate(7.275 3.088)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3"/>
`,
];

export const ltRainRate = [
  "20.072 20.095",
  `
    <path id="Path_3207" data-name="Path 3207" d="M24,19.5v6.833" transform="translate(-11.306 -9.251)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3208" data-name="Path 3208" d="M12,19.5v6.833" transform="translate(-6.073 -9.251)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3209" data-name="Path 3209" d="M18,22.5v6.833" transform="translate(-8.69 -10.543)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3210" data-name="Path 3210" d="M17.568,14.806a4.277,4.277,0,0,0-1.692-8.182H14.81A6.748,6.748,0,0,0,1.937,5.893a6.874,6.874,0,0,0,2.1,7.778" transform="translate(-1.49 -1.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
`,
];

export const ltRaining = [
  "21.77 20.089",
  `
  <path id="IsRaining" d="M21.97,12.395a10.273,10.273,0,0,0-20.47,0Zm-4.652,6.576a2.792,2.792,0,1,1-5.583,0V12.395" transform="translate(-0.85 -2.35)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
`,
];

export const ltMobile = [
  "12 20",
  `
  <g id="Group_3701" data-name="Group 3701" transform="translate(-1250 -21)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(1250 21)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="12" height="20" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="10.7" height="18.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="1.356" transform="translate(1255.5 37.271)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`,
];

export const ltTablet = [
  "16 20",
  `
  <g id="Group_3704" data-name="Group 3704" transform="translate(0.125)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(-0.125 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="16" height="20" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="14.7" height="18.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="2.712" transform="translate(6.541 16.271)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`,
];

export const ltLaptop = [
  "22 20",
  `
  <g id="Group_3702" data-name="Group 3702" transform="translate(-0.108)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(0.108 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="22" height="20" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="20.7" height="18.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="2.712" transform="translate(9.785 16.271)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`,
];

export const ltDesktop = [
  "24 20.65",
  `
  <g id="Group_3703" data-name="Group 3703" transform="translate(0.083)">
    <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(-0.083 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
      <rect width="24" height="18" rx="2" stroke="none"/>
      <rect x="0.65" y="0.65" width="22.7" height="16.7" rx="1.35" fill="none"/>
    </g>
    <line id="Line_464" data-name="Line 464" x2="8.136" transform="translate(7.919 20)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
    <line id="Line_478" data-name="Line 478" y2="1.5" transform="translate(11.988 18)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`,
];

export const ltLargeDesktop = [
  "28 20.65",
  `
  <g id="Group_3708" data-name="Group 3708" transform="translate(4719.241 1241)">
    <g id="Group_3706" data-name="Group 3706" transform="translate(-4719 -1241)">
      <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(-0.241 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
        <rect width="28" height="18" rx="2" stroke="none"/>
        <rect x="0.65" y="0.65" width="26.7" height="16.7" rx="1.35" fill="none"/>
      </g>
      <line id="Line_464" data-name="Line 464" x2="8.136" transform="translate(9.784 20)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
    </g>
    <line id="Line_479" data-name="Line 479" y2="1.5" transform="translate(-4705.24 -1223)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
  </g>
`,
];

export const ltExtraLargeDesktop = [
  "30 20.15",
  `
  <g id="Group_3709" data-name="Group 3709" transform="translate(4684 1241)">
    <g id="Group_3707" data-name="Group 3707" transform="translate(-4684.715 -1241)">
      <g id="Rectangle_19879" data-name="Rectangle 19879" transform="translate(0.715 0)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-width="1.3">
        <rect width="30" height="18" rx="2" stroke="none"/>
        <rect x="0.65" y="0.65" width="28.7" height="16.7" rx="1.35" fill="none"/>
      </g>
      <line id="Line_464" data-name="Line 464" x2="13" transform="translate(9.215 19.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-width="1.3"/>
    </g>
  </g>
`,
];

export const ltSave = [
  "18.47 18.47",
  `
  <g id="Icon_feather-save" data-name="Icon feather-save" transform="translate(0.65 0.65)">
    <path id="Path_2967" data-name="Path 2967" d="M19.762,21.67H6.408A1.908,1.908,0,0,1,4.5,19.762V6.408A1.908,1.908,0,0,1,6.408,4.5H16.9L21.67,9.269V19.762A1.908,1.908,0,0,1,19.762,21.67Z" transform="translate(-4.5 -4.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_2968" data-name="Path 2968" d="M20.039,27.131V19.5H10.5v7.631" transform="translate(-6.684 -9.961)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_2969" data-name="Path 2969" d="M10.5,4.5V9.269h7.631V4.5" transform="translate(-6.684 -4.5)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`,
];

export const ltLayers = [
  "24.916 18.679",
  `
  <g id="Icon_feather-layers" data-name="Icon feather-layers" transform="translate(-2.128 -2.35)">
    <path id="Path_3136" data-name="Path 3136" d="M14.586,3,3,8.793l11.586,5.793L26.171,8.793Z" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
    <path id="Path_3138" data-name="Path 3138" d="M3,18l11.586,5.793L26.171,18" transform="translate(0 -3.414)" fill="none" stroke="var(--ci-primary-color, currentColor)" stroke-linecap="square" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
`,
];

export const ltPencil = [
  "17.17 17.17",
  `
  <path id="cil-pencil" d="M22.583,32.918l0,0a.9.9,0,0,0,.638.266.913.913,0,0,0,.294-.049l5.148-1.746,9.878-9.878a3.22,3.22,0,1,0-4.554-4.554l-9.878,9.878-1.746,5.147a.9.9,0,0,0,.217.932ZM34.81,17.781a2.06,2.06,0,1,1,2.913,2.913l-1.3,1.3L33.511,19.08Zm-9.686,9.686L32.691,19.9,35.6,22.814l-7.567,7.567-4.409,1.5Z" transform="translate(-22.317 -16.018)"/>
`,
];

export const ltPlus = [
  "17 17.001",
  `
  <rect id="Rectangle_2" data-name="Rectangle 2" width="1.3" height="17.001" transform="translate(8)"/>
  <rect id="Rectangle_3" data-name="Rectangle 3" width="1.3" height="17.001" transform="translate(17 8) rotate(90)"/>
`,
];

export const ltSeparator = [
  "1 28",
  `
  <rect id="Divider" width="1" height="28" fill="var(--ci-primary-color, currentColor)"/>
`,
];

export const ltOptions = [
  "16 16",
  `
  <rect id="Rectangle_449" data-name="Rectangle 449" width="16" height="16" fill="none"/>
  <path id="Union_1" data-name="Union 1" d="M0,13.5A1.5,1.5,0,1,1,1.5,15,1.5,1.5,0,0,1,0,13.5Zm0-6A1.5,1.5,0,1,1,1.5,9,1.5,1.5,0,0,1,0,7.5Zm0-6A1.5,1.5,0,1,1,1.5,3,1.5,1.5,0,0,1,0,1.5Z" transform="translate(7 1)"/>
`,
];

export const ltClose = [
  "13.152 12.941",
  `
  <g id="Group_3710" data-name="Group 3710" transform="translate(6.576 -5.657) rotate(45)">
    <rect id="Rectangle_2" data-name="Rectangle 2" width="1.3" height="17.001" transform="translate(8)"/>
    <rect id="Rectangle_3" data-name="Rectangle 3" width="1.3" height="17.001" transform="translate(17 8) rotate(90)"/>
  </g>
`,
];

// export const cisChevronLeft = [
//   `
//   <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>`
// ]

export const ltCalendar = [
  "18.896 17.322",
  `
  <path id="Path_474" data-name="Path 474" d="M33.952,42.2H30.487V40h-1.26v2.2H21.669V40h-1.26v2.2H16.945a.946.946,0,0,0-.945.945V56.377a.946.946,0,0,0,.945.945H33.952a.946.946,0,0,0,.945-.945V43.149a.946.946,0,0,0-.945-.945Zm-.315,13.857H17.26v-12.6h3.149v1.575h1.26V43.464h7.559v1.575h1.26V43.464h3.149Z" transform="translate(-16 -40)"/>
  <rect id="Rectangle_16924" data-name="Rectangle 16924" width="1.18" height="1.18" transform="translate(3.946 7.373)"/>
  <rect id="Rectangle_16925" data-name="Rectangle 16925" width="1.18" height="1.18" transform="translate(7.389 7.373)"/>
  <rect id="Rectangle_16926" data-name="Rectangle 16926" width="1.18" height="1.18" transform="translate(10.52 7.373)"/>
  <rect id="Rectangle_16927" data-name="Rectangle 16927" width="1.18" height="1.18" transform="translate(13.964 7.373)"/>
  <rect id="Rectangle_16928" data-name="Rectangle 16928" width="1.18" height="1.18" transform="translate(3.946 10.192)"/>
  <rect id="Rectangle_16929" data-name="Rectangle 16929" width="1.18" height="1.18" transform="translate(7.389 10.192)"/>
  <rect id="Rectangle_16930" data-name="Rectangle 16930" width="1.18" height="1.18" transform="translate(10.52 10.192)"/>
  <rect id="Rectangle_16931" data-name="Rectangle 16931" width="1.18" height="1.18" transform="translate(13.964 10.192)"/>
  <rect id="Rectangle_16932" data-name="Rectangle 16932" width="1.18" height="1.18" transform="translate(3.946 13.01)"/>
  <rect id="Rectangle_16933" data-name="Rectangle 16933" width="1.18" height="1.18" transform="translate(7.389 13.01)"/>
  <rect id="Rectangle_16934" data-name="Rectangle 16934" width="1.18" height="1.18" transform="translate(10.52 13.01)"/>
  <rect id="Rectangle_16935" data-name="Rectangle 16935" width="1.18" height="1.18" transform="translate(13.964 13.01)"/>
  `,
];

export const ltMachine = [
  "14.268 20.043",
  `
  <svg xmlns="http://www.w3.org/2000/svg" width="14.268" height="20.043" viewBox="0 0 14.268 20.043">
    <path id="Shape" d="M42.317,1H29.951A.951.951,0,0,0,29,1.951V5.077a.913.913,0,0,0,.476.813,1.027,1.027,0,0,0,.637.138c1.365,3.42,1.051,2.625,1.536,3.833a.951.951,0,0,0-.747.923V12.21a.951.951,0,0,0,.951.951h.476v1.427a.951.951,0,0,0,.951.951h.476v1.7a.476.476,0,0,0,.19.38L35.658,18.9v1.665a.476.476,0,0,0,.951,0V18.9l1.712-1.284a.476.476,0,0,0,.19-.38v-1.7h.476a.951.951,0,0,0,.951-.951V13.162h.476a.951.951,0,0,0,.951-.951V10.784a.951.951,0,0,0-.737-.923l1.536-3.833h.152a.951.951,0,0,0,.951-.951V1.951A.951.951,0,0,0,42.317,1ZM37.561,17l-1.427,1.07L34.707,17V15.54h2.854Zm2.854-4.79H33.28a.476.476,0,1,0,0,.951h5.707v1.427H33.28V13.162h.951a.476.476,0,1,0,0-.951H31.853V10.784h8.561Zm-.8-2.378H32.653l-1.522-3.8h9.988Zm2.7-4.756H29.951V1.951H42.317Z" transform="translate(-29 -1)"/>
  </svg>
`,
];

export const ltMachine2 = [
  "23.733 23.223",
  `
  <g id="Group_2558" data-name="Group 2558" transform="translate(-12479 -12667.512)">
    <g id="Group_1054" data-name="Group 1054" transform="translate(12486.58 12681.188)">
      <g id="Group_1053" data-name="Group 1053" transform="translate(0)">
        <path id="Path_27" data-name="Path 27" d="M500.6,265.5a1.371,1.371,0,1,0,1.371,1.371A1.373,1.373,0,0,0,500.6,265.5Z" transform="translate(-499.229 -265.497)"/>
      </g>
    </g>
    <path id="Path_28" data-name="Path 28" d="M356.99-2.054a.437.437,0,0,0-.606-.121l-1.257.838-1.88-.313a1.37,1.37,0,0,0,.547-1.1,1.37,1.37,0,0,0-.547-1.1l1.88-.313,1.257.838a.435.435,0,0,0,.242.073.436.436,0,0,0,.364-.195.437.437,0,0,0-.121-.606l-1.4-.934a.437.437,0,0,0-.314-.067l-2.412.4a.9.9,0,0,0-.678.526h-.107a1.373,1.373,0,0,0-1.3.934h-1.6a1.373,1.373,0,0,0-1.3-.934H334.728a1.574,1.574,0,0,0-.715.227,1.364,1.364,0,0,0-.636.836,1.364,1.364,0,0,0,.141,1.041l5.81,11.563-.719,4.947h-.062a.9.9,0,0,0-.9.9v1.9h-.964a.437.437,0,0,0,0,.874h11.209a.437.437,0,0,0,0-.874h-.964V15.4a.9.9,0,0,0-.9-.9h-.062l-.724-5.007a2.976,2.976,0,0,0-2.4-2.68l-3.584-5.66,4.769,2.457a1.369,1.369,0,0,0,1.582-.235l1.011.521a1.372,1.372,0,0,0,.728,1.426l.415.214a1.369,1.369,0,0,0,1.582-.235l2.294,1.182a.9.9,0,1,0,.4-.777l-2.293-1.181A1.372,1.372,0,0,0,349,3.094l-.415-.214a1.369,1.369,0,0,0-1.582.235L346,2.594a1.373,1.373,0,0,0-.727-1.425l-4.938-2.544h7.422a1.373,1.373,0,0,0,1.3-.934h1.6a1.373,1.373,0,0,0,1.3.934h.107a.9.9,0,0,0,.678.526l2.412.4a.439.439,0,0,0,.072.006.437.437,0,0,0,.242-.073l1.4-.934A.437.437,0,0,0,356.99-2.054ZM346.018,15.37a.03.03,0,0,1,.03.03v1.9h-7.533V15.4a.03.03,0,0,1,.03-.03H344.5a.4.4,0,0,1,.12,0ZM344.36,9.557l.719,4.94h-.462a.39.39,0,0,1-.12,0h-5.012l.719-4.94a2.085,2.085,0,0,1,4.157,0Zm-2.619-2.748a2.985,2.985,0,0,0-2.09,1.561L334.273-2.453a.5.5,0,0,1,.18-.682.506.506,0,0,1,.652.135Zm3.127-4.863a.5.5,0,1,1-.455.884L338.078-.434l-.692-.941h1.037Zm2.885-4.194h-11.01l-.732-.994h11.742a.5.5,0,0,1,.5.5A.5.5,0,0,1,347.753-2.249Z" transform="translate(12145.669 12672.563)"/>
  </g>
`,
];

export const ltEnergy = [
  "19 19",
  `

  <g id="Group_4030" data-name="Group 4030" transform="translate(-3286 93)">
    <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" transform="translate(3286 -93)" fill="#fff" opacity="0"/>
    <g id="lighting" transform="translate(3170 -93)">
      <path id="Path_2494" data-name="Path 2494" d="M121.291,18.81a.553.553,0,0,0,.709-.163l8.848-12.2a.553.553,0,0,0-.448-.878h-2.345l2.82-4.731A.553.553,0,0,0,130.4,0h-6.636a.553.553,0,0,0-.537.419L121.016,9.3a.553.553,0,0,0,.537.687h2.521l-3.039,8.139A.553.553,0,0,0,121.291,18.81Zm4.1-9.179a.553.553,0,0,0-.518-.746H122.26L124.2,1.106h5.231l-2.82,4.731a.553.553,0,0,0,.475.836h2.234L123.5,14.7Z" transform="translate(0 0)"/>
    </g>
  </g>

`,
];

export const ltTeamCenter = [
  "19 19",
  `
  <g id="Group_4033" data-name="Group 4033" transform="translate(-3256.98 93)">
    <g id="Group_4031" data-name="Group 4031" transform="translate(-29)">
      <rect id="Rectangle_20098" data-name="Rectangle 20098" width="19" height="19" transform="translate(3286 -93)" fill="#fff" opacity="0"/>
      <g id="Group_4032" data-name="Group 4032" transform="translate(3264.678 -862.828)">
        <path id="Icon_awesome-comment-alt" data-name="Icon awesome-comment-alt" d="M15.651,0H2.236A2.238,2.238,0,0,0,0,2.236V12.3a2.238,2.238,0,0,0,2.236,2.236H5.59v2.935a.42.42,0,0,0,.667.339l4.363-3.273h5.031A2.238,2.238,0,0,0,17.887,12.3V2.236A2.238,2.238,0,0,0,15.651,0Z" transform="translate(21.852 770.507)" fill="none" stroke="#000" stroke-width="1.1"/>
        <line id="Line_514" data-name="Line 514" x2="12.522" transform="translate(24.544 775.987)" fill="none" stroke="#000" stroke-width="1.1"/>
        <line id="Line_515" data-name="Line 515" x2="12.522" transform="translate(24.544 779.28)" fill="none" stroke="#000" stroke-width="1.1"/>
      </g>
    </g>
  </g>
`,
];

export const ltProcessing = [
  "20.614 20.613",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="20.614" height="20.613" viewBox="0 0 20.614 20.613">
  <g id="Group_3818" data-name="Group 3818" transform="translate(0.1 0.1)">
    <g id="Layer_1" transform="translate(0 0)">
      <path id="Path_3250" data-name="Path 3250" d="M22.281,16.948A9.355,9.355,0,0,1,6.294,26.156H9.253v-.851H5v4.253h.851V26.953a10.209,10.209,0,0,0,17.227-10.3Z" transform="translate(-3.299 -9.995)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
      <path id="Path_3251" data-name="Path 3251" d="M15.46,6.1h4.253V1.851h-.851v2.6a10.21,10.21,0,0,0-17.227,10.3l.8-.3A9.355,9.355,0,0,1,18.419,5.253H15.46Z" transform="translate(-1 -1)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
    </g>
  </g>
</svg>
`,
];

export const ltPending = [
  "20.614 20.614",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="20.614" height="20.614" viewBox="0 0 20.614 20.614">
  <g id="Group_3819" data-name="Group 3819" transform="translate(0.1 0.1)">
    <g id="Layer_1" transform="translate(0 0)">
      <path id="Path_3252" data-name="Path 3252" d="M11.207,1a10.17,10.17,0,0,0-3.55.635l.3.8a9.393,9.393,0,1,1-2.7,1.563V6.954H6.1V2.7H1.851v.851H4.456A10.207,10.207,0,1,0,11.207,1Z" transform="translate(-1 -1)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
      <path id="Path_3253" data-name="Path 3253" d="M17.851,22.655H17v.851h6.8v-.851h-.851V21.2L21,19.253l1.95-1.95V15.851H23.8V15H17v.851h.851V17.3l1.95,1.95-1.95,1.95Zm.851-5.7v-1.1h3.4v1.1l-1.7,1.7Zm1.7,2.9,1.7,1.7v1.1H18.7v-1.1Z" transform="translate(-10.195 -9.046)" fill="#5797a0" stroke="#5797a0" stroke-width="0.2"/>
    </g>
  </g>
</svg>
`,
];

export const ltSent = [
  "29.49 29.49",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="29.49" height="29.49" viewBox="0 0 29.49 29.49">
  <g id="Group_3821" data-name="Group 3821" transform="translate(0 0)">
    <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(8.868 10.642)">
      <path id="Path_3248" data-name="Path 3248" d="M22.175,3,16.5,8.675" transform="translate(-11.857 -3)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_3249" data-name="Path 3249" d="M13.319,3,9.707,13.319,7.643,8.675,3,6.612Z" transform="translate(-3 -3)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
    <g id="Group_3745" data-name="Group 3745" transform="translate(0 14.745) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#5797a0" stroke-width="1">
        <rect width="20.853" height="20.853" rx="10.426" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.853" height="19.853" rx="9.926" fill="none"/>
      </g>
    </g>
  </g>
</svg>

`,
];

export const ltAcknowledge = [
  "27.39 21.807",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="27.39" height="21.807" viewBox="0 0 27.39 21.807">
  <g id="Group_3822" data-name="Group 3822" transform="translate(0.5 0.5)">
    <g id="Group_3751" data-name="Group 3751" transform="translate(0 0)">
      <g id="Icon_feather-send" data-name="Icon feather-send" transform="translate(3.69 5.869)">
        <path id="Path_3248" data-name="Path 3248" d="M226.735-55.358l-5.426,5.426" transform="translate(-216.873 55.358)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        <path id="Path_3249" data-name="Path 3249" d="M225.939-55.358l-3.45,9.865-1.973-4.439L216.077-51.9Z" transform="translate(-216.077 55.358)" fill="none" stroke="#5797a0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      </g>
      <g id="Group_3749" data-name="Group 3749" transform="translate(0 0)">
        <g id="Path_3256" data-name="Path 3256">
          <path id="Path_3257" data-name="Path 3257" d="M231.459-53.172a9.806,9.806,0,0,0-2.865-6.214,9.822,9.822,0,0,0-6.988-2.894,9.815,9.815,0,0,0-6.986,2.9m0,0a9.815,9.815,0,0,0-2.9,6.988,9.815,9.815,0,0,0,2.9,6.988,9.814,9.814,0,0,0,6.986,2.894,8.966,8.966,0,0,0,5.236-1.5c.343-.243,1.312-1.05,1.312-1.05" transform="translate(-211.725 62.281)" fill="none" stroke="#5797a0" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </g>
      <g id="Group_3750" data-name="Group 3750" transform="translate(12.612 7.028)">
        <g id="Group_3748" data-name="Group 3748" transform="translate(0 0)">
          <g id="Rectangle_17651" data-name="Rectangle 17651">
            <path id="Path_3258" data-name="Path 3258" d="M229.933-50.659h0a5.269,5.269,0,0,1,7.451,0h0a5.269,5.269,0,0,1,0,7.451h0a5.269,5.269,0,0,1-7.451,0h0a5.268,5.268,0,0,1,0-7.451Z" transform="translate(-226.873 53.719)" fill="none"/>
            <rect id="Rectangle_19897" data-name="Rectangle 19897" width="9.596" height="9.596" rx="4.798" transform="translate(0 6.786) rotate(-45)" fill="none" stroke="#5797a0" stroke-width="1"/>
          </g>
        </g>
        <path id="Checkbox" d="M233.5-44.364l-2.273-2.273.461-.461,1.813,1.78,3-3,.461.461Z" transform="translate(-227.304 53.127)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
      </g>
    </g>
  </g>
</svg>
`,
];

export const ltDeployok = [
  "29.07 29.07",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="29.07" height="29.07" viewBox="0 0 29.07 29.07">
  <g id="Group_3823" data-name="Group 3823" transform="translate(0 0)">
    <g id="Group_3755" data-name="Group 3755" transform="translate(0 14.535) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#5797a0" stroke-width="1">
        <rect width="20.556" height="20.556" rx="10.278" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.556" height="19.556" rx="9.778" fill="none"/>
      </g>
    </g>
    <g id="Group_3756" data-name="Group 3756" transform="translate(16.949 6.464) rotate(45)">
      <path id="Path_3266" data-name="Path 3266" d="M5.815,12.224c-.087.122-.169.23-.244.342a.549.549,0,0,0-.076.161c-.075.273-.152,1.182-.213,1.458-.043.2-.131.484-.8.484-.5,0-1,0-1.491-.033a.62.62,0,0,1-.625-.586c-.022-.176-.085-.984-.127-1.157a.67.67,0,0,0-.209-.414c-.067-.054-.085-.168-.138-.279l-.736.434c-.185.11-.367.225-.552.333-.222.129-.409.04-.435-.215C.106,12.124.043,11.493,0,10.861a.482.482,0,0,1,.14-.317c.4-.422.816-.839,1.241-1.24a.579.579,0,0,0,.194-.531c-.077-.87-.124-1.743-.19-2.615A7.285,7.285,0,0,1,2.532,1.7,8.757,8.757,0,0,1,3.85,0c.2.233.411.448.593.683A9.53,9.53,0,0,1,6.007,3.568a7.721,7.721,0,0,1,.266,3.014c-.058.774-.113,1.548-.179,2.32a.347.347,0,0,0,.117.306c.44.431.869.872,1.311,1.3a.506.506,0,0,1,.161.461c-.06.544-.117,1.09-.147,1.636-.022.4-.207.542-.586.291-.343-.226-.7-.426-1.056-.637A.569.569,0,0,0,5.815,12.224ZM3.866.78c-.1.1-.159.148-.208.21A7.258,7.258,0,0,0,1.905,6.315q.259,2.849.509,5.7a.257.257,0,0,0,.221.251,4.662,4.662,0,0,0,2.407,0,.279.279,0,0,0,.229-.285c.138-1.5.3-2.988.421-4.485a19.607,19.607,0,0,0,.079-2.415A6.653,6.653,0,0,0,4.557,1.726C4.347,1.4,4.1,1.1,3.866.78ZM1.657,9.752c-.39.389-.746.744-1.1,1.1a.141.141,0,0,0-.038.1c.038.458.08.915.124,1.392.391-.235.76-.453,1.125-.677a.17.17,0,0,0,.079-.12C1.79,10.965,1.726,10.385,1.657,9.752Zm4.369,0c-.066.627-.129,1.2-.184,1.774a.181.181,0,0,0,.073.14c.364.224.732.442,1.124.676.043-.478.085-.935.123-1.393a.141.141,0,0,0-.04-.1C6.769,10.5,6.414,10.142,6.026,9.754Zm-1.1,3.109c-.376.025-.731.067-1.086.067s-.707-.042-1.075-.067c.036.143.08.927.106,1.076a.2.2,0,0,0,.209.191c.447.014.895.024,1.342.026.233.011.3-.1.314-.213.041-.271.075-.541.12-.8C4.869,13.052,4.9,12.959,4.922,12.864Z" transform="translate(0 0)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
      <path id="Path_3268" data-name="Path 3268" d="M2.165.369a1.282,1.282,0,0,1,.007,1.808,1.294,1.294,0,0,1-1.8,0A1.274,1.274,0,0,1,2.165.369ZM.738.739a.747.747,0,0,0,0,1.072A.757.757,0,0,0,1.813,1.8.747.747,0,0,0,1.807.743.736.736,0,0,0,.738.739Z" transform="translate(2.57 4.133)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
    </g>
  </g>
</svg>
`,
];

export const ltDeployNotok = [
  "29.07 29.07",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="29.07" height="29.07" viewBox="0 0 29.07 29.07">
  <g id="Group_3824" data-name="Group 3824" transform="translate(0 0)">
    <g id="Group_3757" data-name="Group 3757" transform="translate(0 14.535) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#5797a0" stroke-width="1">
        <rect width="20.556" height="20.556" rx="10.278" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.556" height="19.556" rx="9.778" fill="none"/>
      </g>
    </g>
    <line id="Line_473" data-name="Line 473" x1="12.238" y1="15.735" transform="translate(8.305 6.882)" fill="none" stroke="#5797a0" stroke-width="1"/>
    <g id="Group_3758" data-name="Group 3758" transform="translate(16.949 6.464) rotate(45)">
      <path id="Path_3266" data-name="Path 3266" d="M5.815,12.224c-.087.122-.169.23-.244.342a.549.549,0,0,0-.076.161c-.075.273-.152,1.182-.213,1.458-.043.2-.131.484-.8.484-.5,0-1,0-1.491-.033a.62.62,0,0,1-.625-.586c-.022-.176-.085-.984-.127-1.157a.67.67,0,0,0-.209-.414c-.067-.054-.085-.168-.138-.279l-.736.434c-.185.11-.367.225-.552.333-.222.129-.409.04-.435-.215C.106,12.124.043,11.493,0,10.861a.482.482,0,0,1,.14-.317c.4-.422.816-.839,1.241-1.24a.579.579,0,0,0,.194-.531c-.077-.87-.124-1.743-.19-2.615A7.285,7.285,0,0,1,2.532,1.7,8.757,8.757,0,0,1,3.85,0c.2.233.411.448.593.683A9.53,9.53,0,0,1,6.007,3.568a7.721,7.721,0,0,1,.266,3.014c-.058.774-.113,1.548-.179,2.32a.347.347,0,0,0,.117.306c.44.431.869.872,1.311,1.3a.506.506,0,0,1,.161.461c-.06.544-.117,1.09-.147,1.636-.022.4-.207.542-.586.291-.343-.226-.7-.426-1.056-.637A.569.569,0,0,0,5.815,12.224ZM3.866.78c-.1.1-.159.148-.208.21A7.258,7.258,0,0,0,1.905,6.315q.259,2.849.509,5.7a.257.257,0,0,0,.221.251,4.662,4.662,0,0,0,2.407,0,.279.279,0,0,0,.229-.285c.138-1.5.3-2.988.421-4.485a19.607,19.607,0,0,0,.079-2.415A6.653,6.653,0,0,0,4.557,1.726C4.347,1.4,4.1,1.1,3.866.78ZM1.657,9.752c-.39.389-.746.744-1.1,1.1a.141.141,0,0,0-.038.1c.038.458.08.915.124,1.392.391-.235.76-.453,1.125-.677a.17.17,0,0,0,.079-.12C1.79,10.965,1.726,10.385,1.657,9.752Zm4.369,0c-.066.627-.129,1.2-.184,1.774a.181.181,0,0,0,.073.14c.364.224.732.442,1.124.676.043-.478.085-.935.123-1.393a.141.141,0,0,0-.04-.1C6.769,10.5,6.414,10.142,6.026,9.754Zm-1.1,3.109c-.376.025-.731.067-1.086.067s-.707-.042-1.075-.067c.036.143.08.927.106,1.076a.2.2,0,0,0,.209.191c.447.014.895.024,1.342.026.233.011.3-.1.314-.213.041-.271.075-.541.12-.8C4.869,13.052,4.9,12.959,4.922,12.864Z" transform="translate(0 0)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
      <path id="Path_3268" data-name="Path 3268" d="M2.165.369a1.282,1.282,0,0,1,.007,1.808,1.294,1.294,0,0,1-1.8,0A1.274,1.274,0,0,1,2.165.369ZM.738.739a.747.747,0,0,0,0,1.072A.757.757,0,0,0,1.813,1.8.747.747,0,0,0,1.807.743.736.736,0,0,0,.738.739Z" transform="translate(2.57 4.133)" fill="#5797a0" stroke="#5797a0" stroke-width="0.3"/>
    </g>
  </g>
</svg>
`,
];

export const ltCanceled = [
  "29.07 29.07",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="29.07" height="29.07" viewBox="0 0 29.07 29.07">
  <g id="Group_3825" data-name="Group 3825" transform="translate(0 0)">
    <path id="Union_38" data-name="Union 38" d="M4.218,9.642V5.424H0V4.218H4.218V0H5.424V4.218H9.642V5.424H5.424V9.642Z" transform="translate(7.717 14.535) rotate(-45)" fill="#ff3434"/>
    <g id="Group_3789" data-name="Group 3789" transform="translate(0 14.535) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#ff3434" stroke-width="1">
        <rect width="20.556" height="20.556" rx="10.278" stroke="none"/>
        <rect x="0.5" y="0.5" width="19.556" height="19.556" rx="9.778" fill="none"/>
      </g>
    </g>
  </g>
</svg>

`,
];

export const ltCancel = [
  "26.625 26.625",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="26.625" height="26.625" viewBox="0 0 26.625 26.625">
  <g id="Group_3813" data-name="Group 3813" transform="translate(0 0)">
    <path id="Union_38" data-name="Union 38" d="M3.863,8.831V4.967H0v-1.1H3.863V0h1.1V3.863H8.831v1.1H4.968V8.831Z" transform="translate(7.068 13.312) rotate(-45)"/>
    <g id="Group_3789" data-name="Group 3789" transform="translate(0 13.312) rotate(-45)">
      <g id="Rectangle_17651" data-name="Rectangle 17651" fill="none" stroke="#000" stroke-width="1">
        <rect width="18.827" height="18.827" rx="9.413" stroke="none"/>
        <rect x="0.5" y="0.5" width="17.827" height="17.827" rx="8.913" fill="none"/>
      </g>
    </g>
  </g>
</svg>
`,
];

export const ltLeft = [
  "13.882 25.905",
  `
<svg xmlns='http://www.w3.org/2000/svg' width='13.882' height='25.905' viewBox='0 0 13.882 25.905'><path id='Icon_metro-chevron-thin-left' data-name='Icon metro-chevron-thin-left' d='M24,28.408c0-.012-1.488,1.536-1.495,1.51l-12.077-12.2a1.074,1.074,0,0,1,0-1.51L22.5,4.013c.038,0,1.508,1.5,1.495,1.51L12.982,16.967,24,28.408Z' transform='translate(-10.115 -4.013)'/></svg>
`,
];

export const ltDropdownIndicator = [
  "20 20",
  `
<svg xmlns='http://www.w3.org/2000/svg' height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
`,
];

export const ltInterface = [
  "23 23",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
  <g id="Group_3818" data-name="Group 3818" transform="translate(-1279 -18835)">
    <g id="Group_3818-2" data-name="Group 3818" transform="translate(-301 18425)">
      <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1580 410)" fill="#fff" stroke="#000" stroke-width="1.3">
        <rect width="23" height="23" rx="3" stroke="none"/>
        <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
      </g>
      <path id="Union_37" data-name="Union 37" d="M21.485,0V.844H0V0Z" transform="translate(1580.515 414.018)" stroke="#000" stroke-width="0.3"/>
      <path id="Union_38" data-name="Union 38" d="M10.485,0V.844H0V0Z" transform="translate(1585.922 417.697) rotate(90)" stroke="#000" stroke-width="0.3"/>
      <path id="Union_39" data-name="Union 39" d="M10.485,0V.844H0V0Z" transform="translate(1591.922 417.697) rotate(90)" stroke="#000" stroke-width="0.3"/>
      <path id="Union_40" data-name="Union 40" d="M10.485,0V.844H0V0Z" transform="translate(1597.922 417.697) rotate(90)" stroke="#000" stroke-width="0.3"/>
    </g>
    <circle id="Ellipse_432" data-name="Ellipse 432" cx="1.5" cy="1.5" r="1.5" transform="translate(1283 18847)"/>
    <circle id="Ellipse_433" data-name="Ellipse 433" cx="1.5" cy="1.5" r="1.5" transform="translate(1295 18849)"/>
    <circle id="Ellipse_434" data-name="Ellipse 434" cx="1.5" cy="1.5" r="1.5" transform="translate(1289 18844)"/>
  </g>
</svg>
`,
];

export const ltMath = [
  "23 23",
  `
<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
  <g id="Group_3817" data-name="Group 3817" transform="translate(-1279 -18914)">
    <g id="Group_3817-2" data-name="Group 3817" transform="translate(-301 18504)">
      <g id="Rectangle_19784" data-name="Rectangle 19784" transform="translate(1580 410)" fill="#fff" stroke="#000" stroke-width="1.3">
        <rect width="23" height="23" rx="3" stroke="none"/>
        <rect x="0.65" y="0.65" width="21.7" height="21.7" rx="2.35" fill="none"/>
      </g>
      <g id="_" data-name="+" transform="translate(1582.76 412.76)">
        <path id="Union_1" data-name="Union 1" d="M3.063,7V3.938H0V3.062H3.063V0h.875V3.062H7v.875H3.938V7Z" transform="translate(0.24 0.24)" stroke="#000" stroke-width="0.3"/>
      </g>
      <g id="_2" data-name="+" transform="translate(1586.468 421.701) rotate(45)">
        <path id="Union_1-2" data-name="Union 1" d="M3.1,7.075V3.98H0V3.1H3.1V0H3.98V3.1h3.1V3.98H3.98v3.1Z" transform="translate(0 0)" stroke="#000" stroke-width="0.3"/>
      </g>
      <path id="Union_35" data-name="Union 35" d="M6.055,0V.844H0V0Z" transform="translate(1593.515 424.444)" stroke="#000" stroke-width="0.3"/>
      <path id="Union_36" data-name="Union 36" d="M6.055,0V.844H0V0Z" transform="translate(1593.515 427.767)" stroke="#000" stroke-width="0.3"/>
      <path id="Union_37" data-name="Union 37" d="M6.055,0V.844H0V0Z" transform="translate(1593.515 416.018)" stroke="#000" stroke-width="0.3"/>
    </g>
  </g>
</svg>
`,
];
